import Axios from "../axios";
import BaseUrl from "../base";

// 数字化水平测试
export function QuestionnaireAdd(data) {
  return Axios({
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    url: BaseUrl.cs + "/questionnaire/add",
    data: data,
  });
}
// 上传方案
export function specialLoanFileAdd(data) {
  return Axios({
    method: "post",
    url: BaseUrl.cs + "/specialLoanFile/add",
    data: data,
  });
}
// 回显
export function getQuestionnaire(id) {
  return Axios({
    headers: {
      "Content-Type": "application/json",
    },
    method: "get",
    url: BaseUrl.cs + "/questionnaire/" + id,
  });
}
//  立即申请判断是否完成
export function isFinish(data) {
  return Axios({
    method: "get",
    url: BaseUrl.cs + "/specialLoanInfo/application",
    params: data,
  });
}
// 结果查询

export function isFinishResult(data) {
  return Axios({
    method: "get",
    url: BaseUrl.cs + "/specialLoanInfo/update",
    params: data,
  });
}
