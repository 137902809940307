import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/BusinessAngency",
    name: "BusinessAngency",
    meta: {
      requireAuth: false,
    },
    component: () => import("../views/BusinessAngency.vue"),
  },
  {
    path: "/FinancialProducts",
    name: "FinancialProducts",
    meta: {
      requireAuth: false,
    },
    component: () => import("../views/FinancialProducts.vue"),
  },
  {
    path: "/PolicyInformation",
    name: "PolicyInformation",
    meta: {
      requireAuth: false,
    },
    component: () => import("../views/PolicyInformation.vue"),
  },
  {
    path: "/FinancialInstitutions",
    name: "FinancialInstitutions",
    meta: {
      requireAuth: false,
    },
    component: () => import("../views/FinancialInstitutions.vue"),
  },
  {
    path: "/ResultsShow",
    name: "ResultsShow",
    meta: {
      requireAuth: false,
    },
    component: () => import("../views/ResultsShow.vue"),
  },
  {
    path: "/ContactUs",
    name: "ContactUs",
    meta: {
      requireAuth: false,
    },
    component: () => import("../views/ContactUs.vue"),
  },
  // 金融专区
  {
    path: "/FinancialPrefecture",
    name: "FinancialPrefecture",
    meta: {
      requireAuth: false,
    },
    component: () => import("../views/FinancialPrefecture.vue"),
  },
  //  数转智改
  {
    path: "/NumberWisdom",
    name: "NumberWisdom",
    meta: {
      requireAuth: false,
    },
    component: () => import("../views/NumberWisdom.vue"),
  },
  //  技术改造
  {
    path: "/Remould",
    name: "Remould",
    meta: {
      requireAuth: false,
    },
    component: () => import("../views/Remold.vue"),
  },
  // 问卷调查
  {
    path: "/Survey",
    name: "Survey",
    meta: {
      requireAuth: false,
    },
    component: () => import("../views/Survey.vue"),
  },
  {
    path: "/Remould",
    name: "Remould",
    meta: {
      requireAuth: false,
    },
    component: () => import("../views/Remold.vue"),
  },
  //  政策列表
  {
    path: "/PolicyList",
    name: "PolicyList",
    meta: {
      requireAuth: false,
    },
    component: () => import("../views/PolicyList.vue"),
  },
  //  政策详情
  {
    path: "/PolicyDetails",
    name: "PolicyDetails",
    meta: {
      requireAuth: false,
    },
    component: () => import("../views/PolicyDetails.vue"),
  },
  //  数字化测评
  {
    path: "/Digitization",
    name: "Digitization",
    meta: {
      requireAuth: false,
    },
    component: () => import("../views/Digitization.vue"),
  },
  {
    path: "/Supervision",
    name: "Supervision",
    meta: {
      requireAuth: false,
    },
    component: () => import("../views/Supervision.vue"),
  },
  {
    path: "/ProdutcDetails",
    name: "ProdutcDetails",
    meta: {
      requireAuth: false,
    },
    component: () => import("../views/ProdutcDetails.vue"),
  },
  {
    path: "/PolicyInformationDetails",
    name: "PolicyInformationDetails",
    meta: {
      requireAuth: false,
    },
    component: () => import("../views/PolicyInformationDetails.vue"),
  },
  {
    path: "/Register",
    name: "Register",
    meta: {
      requireAuth: false,
    },
    component: () => import("../views/Register.vue"),
  },
  {
    path: "/RegisterPhone",
    name: "RegisterPhone",
    meta: {
      requireAuth: false,
    },
    component: () => import("../views/RegisterPhone.vue"),
  },
  {
    path: "/RetrievePassword",
    name: "RetrievePassword",
    meta: {
      requireAuth: false,
    },
    component: () => import("../views/RetrievePassword.vue"),
  },
  // 授权成功页面
  {
    path: "/zfLoginSucess",
    name: "zfLoginSucess",
    meta: {
      requireAuth: false,
    },
    component: () => import("../views/govLoginSucess.vue"),
  },
  // 授权失败页面
  {
    path: "/zfLoginErro",
    name: "zfLoginErro",
    meta: {
      requireAuth: false,
    },
    component: () => import("../views/govLoginErro.vue"),
  },
  {
    path: "/PersonalLayout",
    name: "PersonalLayout",
    redirect: "/PersonalLayout/WareHouse",
    meta: {
      requireAuth: true,
    },
    component: () => import("../views/PersonalLayout.vue"),
    children: [
      // 数据仓库
      {
        path: "/PersonalLayout/WareHouse",
        name: "WareHouse",
        meta: {
          requireAuth: true,
        },
        component: () => import("../views/WareHouse.vue"),
      },
      // 金融服务-企业认证
      {
        path: "/PersonalLayout/EnterpriseCertification",
        name: "EnterpriseCertification",
        meta: {
          requireAuth: true,
        },
        component: () => import("../views/EnterpriseCertification.vue"),
      },
      // 金融服务-个体认证
      {
        path: "/PersonalLayout/IndividualCertification",
        name: "IndividualCertification",
        meta: {
          requireAuth: true,
        },
        component: () => import("../views/IndividualCertification.vue"),
      },
      // 金融服务-需求发布
      {
        path: "/PersonalLayout/PostDemand",
        name: "PostDemand",
        meta: {
          requireAuth: true,
        },
        component: () => import("../views/PostDemand.vue"),
      },
      // 金融服务-资源池
      {
        path: "/PersonalLayout/ResourcePool",
        name: "ResourcePool",
        meta: {
          requireAuth: true,
        },
        component: () => import("../views/ResourcePool.vue"),
      },
      // 金融服务-我的服务
      {
        path: "/PersonalLayout/MyService",
        name: "MyService",
        meta: {
          requireAuth: true,
        },
        component: () => import("../views/MyService.vue"),
      },
      // 金融服务-我的服务详情
      {
        path: "/PersonalLayout/MyServiceDetails",
        name: "MyServiceDetails",
        meta: {
          requireAuth: true,
        },
        component: () => import("../views/MyServiceDetails.vue"),
      },
      // 信用分析-企业诊断
      {
        path: "/PersonalLayout/EnterpriseDiagnosis",
        name: "EnterpriseDiagnosis",
        meta: {
          requireAuth: true,
        },
        component: () => import("../views/EnterpriseDiagnosis.vue"),
      },
      // 信用分析-企业诊断历史
      {
        path: "/PersonalLayout/DiagnosisHistory",
        name: "DiagnosisHistory",
        meta: {
          requireAuth: true,
        },
        component: () => import("../views/DiagnosisHistory.vue"),
      },
      // 信用分析-企业诊断历史详情
      {
        path: "/PersonalLayout/DiagnosisHistoryDetails",
        name: "DiagnosisHistoryDetails",
        meta: {
          requireAuth: true,
        },
        component: () => import("../views/DiagnosisHistoryDetails.vue"),
      },
      {
        path: "/PersonalLayout/JgDiagnosisHistory",
        name: "JgDiagnosisHistory",
        meta: {
          requireAuth: true,
        },
        component: () => import("../views/JgDiagnosisHistory.vue"),
      },
      // 金融服务-资源池-企业详情
      {
        path: "/PersonalLayout/EnterpriseNameDetails",
        name: "EnterpriseNameDetails",
        meta: {
          requireAuth: true,
        },
        component: () => import("../views/EnterpriseNameDetails.vue"),
      },
      // 信用分析-征信报告
      {
        path: "/PersonalLayout/CreditReport",
        name: "CreditReport",
        meta: {
          requireAuth: true,
        },
        component: () => import("../views/CreditReport.vue"),
      },
      // 信用分析-深度分析报告
      {
        path: "/PersonalLayout/InDepthAnalysisReport",
        name: "InDepthAnalysisReport",
        meta: {
          requireAuth: true,
        },
        component: () => import("../views/InDepthAnalysisReport.vue"),
      },
      {
        path: "/PersonalLayout/company",
        name: "company",
        meta: {
          requireAuth: false,
        },
        component: () => import("../views/company.vue"),
      },
      // 个人设置
      {
        path: "/PersonalLayout/Settings",
        name: "Settings",
        meta: {
          requireAuth: true,
        },
        component: () => import("../views/settings.vue"),
      },
      // 发布产品
      {
        path: "/PersonalLayout/ReleaseProduct",
        name: "ReleaseProduct",
        meta: {
          requireAuth: true,
        },
        component: () => import("../views/ReleaseProduct.vue"),
      },
      // 业务受理
      {
        path: "/PersonalLayout/Business",
        name: "Business",
        meta: {
          requireAuth: true,
        },
        component: () => import("../views/Business.vue"),
      },

      // 填写专属深度分析数据
      {
        path: "/PersonalLayout/ReportForms",
        name: "ReportForms",
        meta: {
          requireAuth: true,
        },
        component: () => import("../views/ReportForms.vue"),
        children: [
          // 填写资产负债表
          {
            path: "/ReportForms/ZCFZ",
            name: "ZCFZ",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/components/ZCFZB.vue"),
          },
          // 填写利润表
          {
            path: "/ReportForms/LYB",
            name: "LYB",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/components/LYB.vue"),
          },
          // 填写现金流量表
          {
            path: "/ReportForms/XJLL",
            name: "XJLL",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/components/XJLLB.vue"),
          },
          // 填写财税参数表
          {
            path: "/ReportForms/CSCS",
            name: "CSCS",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/components/CSCSB.vue"),
          },
          // 经营数据-销项发票
          {
            path: "/ReportForms/JYSJ",
            name: "JYSJ",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/components/JYSJ.vue"),
          },
          // 经营数据详情
          {
            path: "/ReportForms/JYSJDetail",
            name: "JYSJDetail",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/components/JYSJDetail.vue"),
          },
          // 经营数据-进项发票
          {
            path: "/ReportForms/JXFP",
            name: "JXFP",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/components/JXFP.vue"),
          },
          // 进项发票详情
          {
            path: "/ReportForms/JXFPDetail",
            name: "JXFPDetail",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/components/JXFPDetail.vue"),
          },
          // 纳税评级列表
          {
            path: "/ReportForms/NSPJ",
            name: "NSPJ",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/components/NSPJ.vue"),
          },
          // 纳税评级详情（或新增）
          {
            path: "/ReportForms/NSPJDetail",
            name: "NSPJDetail",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/components/NSPJDetail.vue"),
          },
          // 个税数据列表
          {
            path: "/ReportForms/GSSJ",
            name: "GSSJ",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/components/GSSJ.vue"),
          },
          // 个税数据详情（或新增）
          {
            path: "/ReportForms/GSSJDetail",
            name: "GSSJDetail",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/components/GSSJDetail.vue"),
          },
          // 能耗数据-电费列表
          {
            path: "/ReportForms/DFSJ",
            name: "DFSJ",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/components/DFSJ.vue"),
          },
          // 能耗数据-电费详情
          {
            path: "/ReportForms/DFSJDetail",
            name: "DFSJDetail",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/components/DFSJDetail.vue"),
          },
          // 能耗数据-水费列表
          {
            path: "/ReportForms/SFSJ",
            name: "SFSJ",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/components/SFSJ.vue"),
          },
          // 能耗数据-水费详情
          {
            path: "/ReportForms/SFSJDetail",
            name: "SFSJDetail",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/components/SFSJDetail.vue"),
          },
          // 能耗数据-燃气费列表
          {
            path: "/ReportForms/RQSJ",
            name: "RQSJ",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/components/RQSJ.vue"),
          },
          // 能耗数据-燃气费详情
          {
            path: "/ReportForms/RQSJDetail",
            name: "RQSJDetail",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/components/RQSJDetail.vue"),
          },
          // 社保数据-养老保险
          {
            path: "/ReportForms/YLBX",
            name: "YLBX",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/components/YLBX.vue"),
          },
          // 社保数据-养老保险详情（修改或新增）
          {
            path: "/ReportForms/YLBXDetail",
            name: "YLBXDetail",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/components/YLBXDetail.vue"),
          },
          // 社保数据-医疗保险列表(工伤保险、生育保险、住房公积金)
          {
            path: "/ReportForms/OTHER",
            name: "OTHER",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/components/OTHER.vue"),
          },
          // 社保数据-医疗保险详情(工伤保险、生育保险、住房公积金)
          {
            path: "/ReportForms/OTHERDetail",
            name: "OTHERDetail",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/components/OTHERDetail.vue"),
          },
        ],
      },

      // 信用中国  -地方
      {
        path: "/ReportForms/place",
        name: "place",
        meta: {
          requireAuth: false,
        },
        component: () => import("../views/creditchina/place.vue"),
      },
      // 信用中国  -金融机构
      {
        path: "/ReportForms/financialInstitution",
        name: "financialInstitution",
        meta: {
          requireAuth: false,
        },
        component: () =>
          import("../views/creditchina/financialInstitution.vue"),
      },
      // 信用中国  -平台
      {
        path: "/ReportForms/platform",
        name: "platform",
        meta: {
          requireAuth: false,
        },
        component: () => import("../views/creditchina/platform.vue"),
      },
      // 信息管理
      {
        path: "/PersonalLayout/InforManage",
        name: "InforManage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../views/InforManage.vue"),
        children: [
          // 信息发布
          {
            path: "/InforManage/AddInfor",
            name: "AddInfor",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/inforcomponent/AddInfor.vue"),
          },
          // 信息列表
          {
            path: "/InforManage/InforList",
            name: "InforList",
            meta: {
              requireAuth: true,
            },
            component: () => import("../views/inforcomponent/InforList.vue"),
          },
        ],
      },
      // 消息通知
      {
        path: "/PersonalLayout/Message",
        name: "Message",
        meta: {
          requireAuth: true,
        },
        component: () => import("../views/Message.vue"),
      },
      // 消息通知详情
      {
        path: "/PersonalLayout/MessageDetail",
        name: "MessageDetail",
        meta: {
          requireAuth: true,
        },
        component: () => import("../views/MessageDetail.vue"),
      },
    ],
  },
];

const router = createRouter({
  // 生产环境
  history: createWebHashHistory(),
  base: "/crpt30/",
  //// 测试环境
  // history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    let username = localStorage.getItem("userName");
    let isLogin = localStorage.getItem("isLogin");
    if (isLogin === null || username === null) {
      store.commit("loginOut");
    }
    if (username && isLogin) {
      next();
    } else {
      next({ path: "/" });
    }
  } else {
    next();
  }
});

export default router;
