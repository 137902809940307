import { QuestionnaireAdd } from "@/api/digitization/index";
const digitizationModule = {
  state: {},
  getters: {},
  actions: {
    // 数字化评测
    questionnaireAdd({ commit, state, dispatch }, params) {
      return QuestionnaireAdd(params);
    },
  },
  mutations: {},
};

export default digitizationModule;
